import Swiper from 'swiper/swiper-bundle.esm.js'
import 'swiper/swiper-bundle.min.css'

var initSwiper = function () {
  var aboutPage_swiper = new Swiper('.aboutPage-swiper', {
    slidesPerView:2,
    spaceBetween: 25,
    autoHeight: true,
    observeParents: true,
    observer: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView:6,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: '.aboutPage-banner-swiper-next',
      prevEl: '.aboutPage-banner-swiper-prev'
    },
  })
  var work_swiper = new Swiper('.work-swiper', {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 20,
    // autoHeight: true,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.work-swiper-pagination'
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1536: {
        slidesPerView: 3,
        spaceBetween: 100,
      }
    }
  });
  var video_swiper = new Swiper('.video-swiper', {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 20,
    autoHeight: true,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.video-swiper-pagination'
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 40,
      }
    }
  });
 
  var faq_blog_swiper = new Swiper('.faq-blog-swiper', {
    loop: true,
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 20,
    autoHeight: true,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.faq-blog-swiper-pagination',
      clickable:true
    },
    navigation: {
      prevEl: '.faq-blog-swiper-button-prev',
      nextEl: '.faq-blog-swiper-button-next',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 40
      }
    }
  });
  var proMinSwiper = new Swiper(".pro-min-swiper", {
    speed: 400,
    spaceBetween: 8,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 5
      }
    }
  });
  var proMaxSwiper = new Swiper(".pro-max-swiper", {
    speed: 400,
    spaceBetween: 10,
    navigation: {
      nextEl: ".pro-max-swiper-button-next",
      prevEl: ".pro-max-swiper-button-prev",
    },
    thumbs: {
      swiper: proMinSwiper,
    },
  });
  var FAQ_nav_swiper = new Swiper('.FAQ-nav-swiper', {
    freeMode: true,
    speed: 400,
    slidesPerView:'auto',
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    centerInsufficientSlides: true,
    navigation: {
      nextEl: '.FAQ-nav-swiper-button-next',
      prevEl: '.FAQ-nav-swiper-button-prev'
    },
  });
  var milestone_swiper = new Swiper('.milestone-swiper', {
    speed: 400,
    loop: true,
    observer: true,
    observeParents: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".milestone-swiper-button-next",
      prevEl: ".milestone-swiper-button-prev",
    },
  });
  // 上面是fangao加入的
  var latest_swiper = new Swiper('.latestSwiper', {
    slidesPerView: 2,
    loopPreventsSlide: true, //默认true，阻止
    spaceBetween: 20,
    loop: true,
    speed: 400, // 轮播速度
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.latest-swiper-button-next',
      prevEl: '.latest-swiper-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      1280: {
        slidesPerView: 4
      }
    }
  })
  var trusted_swiper1 = new Swiper('.trustedSwiper1', {
    slidesPerView: 3,
    loopPreventsSlide: true, //默认true，阻止
    spaceBetween: 20,
    loop: true,
    speed: 4000, // 轮播速度
    autoplay: {
      delay: 0
    },
    simulateTouch: false, //禁止拖拽+点击
    breakpoints: {
      768: {
        loop: true,
        autoplay: {
          delay: 0
        },
        slidesPerView: 4
      },
      1280: {
        loop: true,
        autoplay: {
          delay: 0
        },
        slidesPerView: 6
      }
    }
  })
  var trusted_swiper2 = new Swiper('.trustedSwiper2', {
    slidesPerView: 3,
    loopPreventsSlide: true, //默认true，阻止
    spaceBetween: 20,
    loop: true,
    speed: 4000, // 轮播速度
    autoplay: {
      delay: 0,
      reverseDirection: true,
      // waitForTransition: false
    },
    simulateTouch: false,
    breakpoints: {
      768: {
        loop: true,
        autoplay: {
          delay: 0
        },
        slidesPerView: 5,
      },
      1280: {
        loop: true,
        autoplay: {
          delay: 0
        },
        slidesPerView: 7,
      }
    }
  })
  var banner_swiper = new Swiper('.banner-swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      768: {
        slidesPerView: 3
      },

    },
    pagination: {
      el: '.banner-swiper-pagination',
      clickable: true
    }
  })
  var hot_swpier = new Swiper('.hot-swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      768: {
        slidesPerView: 3
      },

    },
    pagination: {
      el: '.hot-swiper-pagination',
      clickable: true
    }
  })
  var blog_swpier = new Swiper('.blog-swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      767: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    },
    pagination: {
      el: '.blog-swiper-pagination',
      clickable: true
    },
  })
  var premier_swiper = new Swiper('.premier-swiper', {
    speed: 1000,
    slidesPerView: 1,
    autoplay: {
      delay: 2000,
      stopOnLastSlide: false,
      disableOnInteraction: false
    }
  })
  var home_banner_swiper = new Swiper('.home-banner-swiper', {
    speed: 400,
    loop: true,
    slidesPerView: 1,
    parallax: true,
    scrollbar: {
      el: '.home-banner-swiper-scrollbar'
      // hide: true,
    },
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.home-banner-swiper-next',
      prevEl: '.home-banner-swiper-prev'
    },
    pagination: {
      el: '.home-banner-swiper-pagination',
      clickable: true
    }
  })
  return { premier_swiper }
}

export default initSwiper
